@import '@/_styles/import';
.root {
  cursor: pointer;

  position: fixed;
  z-index: $z-index-search-again;
  top: 1em;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  text-align: center;

  transition: top 0.2s ease-out;

  :global(.Layout__content:has(.GlobalNavigation:not(.-hidden))) & {
    top: 4em;
  }
}

.button {
  display: flex;
  gap: 3px;
  place-content: center;
  align-items: center;

  width: auto;
  height: 40px;
  padding: 0 15px 0 12px;

  line-height: 40px;

  background-color: $light-blue-shade-1;
  border: 0;
  border-radius: 20px;

  svg {
    transform: scaleX(-1);
    width: 24px;
    height: 24px;
  }
}

.text {
  padding-top: 1px;

  font-size: 15px;
  font-weight: bold;
  color: $black;
  letter-spacing: 0;
}
