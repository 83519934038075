@import '@/_styles/import';
.itemContryName {
  @include font($size: rem(12), $line-height: rem(20), $weight: 500, $color: $grey-text);

  padding-top: 10px;
  padding-bottom: 8px;
  letter-spacing: 0;

  &:first-child {
    padding-top: 0;
  }
}

.listBox {
  :global(.ListBoxItem) {
    padding-top: 8px;
    padding-bottom: 8px;

    &:first-child {
      padding-top: 0;
    }
  }

  :global(.ListBoxItem._focused) {
    outline: 2px solid $black;
    outline-offset: -2px;
  }
}
