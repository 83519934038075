@import '@/_styles/import';
.FilterText {
  position: relative;
  display: none;
  justify-content: space-between;
  padding: 0 20px;

  @include media-breakpoint-down(sm) {
    display: flex;
    align-items: center;
  }

  .text {
    color: $grey-text;
    word-break: unset;
  }

  .sailingsCountText {
    font-size: rem(17);
    font-weight: bold;
    color: $red;
  }

  .regionsText {
    .refinementText {
      display: inline-block;
      font-size: rem(17);
      font-weight: bold;
      color: $red;

      .refinementTextSeparator {
        color: $grey-text;
      }
    }

    .control-icon.-default,
    .control-icon.-arrow,
    .control-icon.-edit {
      display: none;
    }
  }

  .refinementText {
    display: inline-block;
    font-size: rem(17);

    .refinementButton,
    .text.action {
      display: inline;
      font-weight: bold;
    }
  }

  .heading {
    display: block;

    height: auto;

    font-size: rem(17);
    line-height: rem(24);
    letter-spacing: 0;
    word-break: normal;
    overflow-wrap: anywhere;

    .refinementText {
      padding-right: 30px;
    }

    b {
      color: $red;
      text-decoration: underline;
    }
  }

  .highlight {
    font-size: rem(17);
    font-weight: bold;
    color: $red;
    text-decoration: underline;

    &.hideTextDecoration {
      text-decoration: none;
    }

    .labelText {
      text-decoration: underline;
    }
  }

  .filterIconMobile {
    transform: scaleX(-1) rotate(359deg);

    width: 40px;
    height: 40px;

    line-height: rem(11);
    color: $red;

    background: transparent;
    border-radius: 50%;
    box-shadow: 0 0 6px 0 $black-003;

    .Icon {
      width: inherit;
      height: inherit;
      padding: 10px;
      background-color: transparent;
    }
  }
}
