@import '@/_styles/import';
.popover {
  > div {
    opacity: 1;
    transition-property: opacity;
  }

  &[data-status='open'] > div,
  &[data-status='close'] > div {
    transition-duration: 450ms;
  }

  &[data-status='initial'] > div,
  &[data-status='close'] > div {
    opacity: 0;
  }
}

.root {
  $side-padding: 23px;

  position: fixed;
  z-index: $z-mobile-refinement-filter;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  background-color: $white;

  .details {
    @include no-scrollbar;

    flex: 1;
    padding: 30px $side-padding 90px;

    .itemsList {
      display: none;

      .listBox {
        padding-top: 210px;
        padding-bottom: 120px;
      }
    }

    .listBox {
      max-width: 428px;
      margin: 0 auto;
      padding: 0 2px;
    }
  }

  .toggle {
    width: 250px;
    margin: 0 auto 30px;
  }

  .subHeader {
    padding: 0 $side-padding 20px;
    border-bottom: 1px solid $lightwhite;

    .destinationTitle {
      padding-top: 20px;
      text-align: center;

      @include font($size: rem(17), $color: $black, $line-height: rem(25), $weight: 700);

      letter-spacing: 0;
    }

    .selectAll {
      max-width: 428px;
      margin: 0 auto;
      padding: 33px 2px 0;
      letter-spacing: 0;

      @include font($size: rem(15), $color: $black, $line-height: rem(24), $weight: 400);
    }
  }

  &._showItems {
    .toggle {
      display: none;
    }

    .header,
    .subHeader {
      position: relative;
      z-index: 1;
      background-color: $gray-085;
      backdrop-filter: blur(2px);
    }

    .header {
      z-index: 2;
      border-bottom: 0;
    }

    .details {
      position: fixed;
      top: 0;
      left: 0;

      overflow-y: auto;

      width: 100vw;
      height: 100vh;
      padding-top: 0;
      padding-bottom: 0;

      .itemsList {
        display: block;
      }

      .destinationsListBox {
        display: none;
      }
    }
  }
}
