@import '@/_styles/import';
$headerPadding: 30px;
$promobannerplace-offset-desktop: 50px;
$promobannerplace-offset-tablet: 40px;
$promobannerplace-offset-mobile: 10px;

.ChooseVoyageTop {
  --packageitineraryresults-body-max-width: 1180px;
  --packageitineraryresults-body-padding: 0;

  height: auto;
  margin: 0 auto;
  padding-bottom: $headerPadding;
  background-color: $white;

  &__shadow_container {
    position: relative;

    &::after {
      content: '';

      position: absolute;
      z-index: 1;

      display: block;

      width: 100%;
      height: $headerPadding;

      box-shadow: 0 3px 6px 0 $light-shadow;
    }
  }

  span.highlighter {
    pointer-events: none;

    position: relative;

    display: block;

    width: 312px;
    height: 10px;
    margin: 0 auto;
    margin-top: -17px;

    text-align: center;

    opacity: 0.2;
    background-color: $light-orange;

    @media (max-width: $container-xs-width) {
      width: 40%;
    }
  }

  h1 {
    width: 100%;
    height: 30px;
    margin: 30px 0;

    font-size: rem(20);
    font-weight: bold;
    line-height: rem(30);
    color: $black;
    text-align: center;
    letter-spacing: 0;

    @media (max-width: $container-xs-width) {
      height: 24px;
      margin: 10px 0 30px;

      font-size: rem(15);
      line-height: rem(24);
      text-align: center;
    }
  }

  .PromoBannerPlace:not(:empty) {
    max-width: var(--packageitineraryresults-body-max-width);
    margin: $promobannerplace-offset-mobile auto;
    padding: var(--packageitineraryresults-body-padding);

    @include media-breakpoint-up(md) {
      margin: $promobannerplace-offset-tablet auto;
    }

    @include media-breakpoint-up(xl) {
      margin: $promobannerplace-offset-desktop auto;
    }

    &._marquee {
      width: 100%;
      max-width: unset;
      margin-top: 0;
      padding: 0;
    }
  }

  @media (max-width: $container-lg1-min-width) {
    --packageitineraryresults-body-max-width: 1087px;
  }

  @media (max-width: $container-md-max-width) {
    --packageitineraryresults-body-max-width: 820px;
  }

  @media (max-width: $container-sm-width-768) {
    --packageitineraryresults-body-max-width: 690px;
  }

  @media (max-width: $container-xs-width) {
    --packageitineraryresults-body-max-width: 380px;
  }

  @media (max-width: $container-xxs-width) {
    --packageitineraryresults-body-max-width: 380px;
  }

  @media (max-width: $container-xxxs-width) {
    --packageitineraryresults-body-max-width: 335px;
  }

  @media (max-width: $container-xs-width) {
    min-height: 125px;
  }

  @include media-breakpoint-between(xs, lg) {
    --packageitineraryresults-body-padding: 0 20px;
  }

  @include media-breakpoint-down(sm) {
    .Flyout__content {
      width: 100%;
    }
  }
}
