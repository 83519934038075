@import '@/_styles/import';
.root {
  min-height: 40px;

  :global(.form-check) {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    label {
      display: flex;
      flex: 1;
      align-items: center;

      &::after {
        top: unset;
      }
    }
  }

  .label {
    flex: 1;

    &Title {
      @include font($size: rem(15), $line-height: rem(24), $weight: 400, $color: $black);
    }

    &Description {
      @include font($size: rem(13), $line-height: rem(20), $weight: 400, $color: $grey-text);

      letter-spacing: 0;
    }
  }

  .image {
    position: relative;
    margin-left: 40px;

    &,
    img {
      display: block;
      width: 64px;
      height: 48px;
    }
  }
}
