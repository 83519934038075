@import '@/_styles/import';
.AppliedFilters {
  overflow: hidden;
  display: flex;
  justify-content: center;

  width: 1243px;
  margin: 0 auto;

  .row {
    overflow: auto hidden;
    flex-wrap: nowrap;
    width: initial;
    margin: 0 auto;

    @include scrollbar($outline: 8px);

    .filter {
      cursor: pointer;
      user-select: none;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: auto;
      height: auto;
      min-height: 32px;
      margin-top: 30px;
      margin-right: 8.03px;
      padding-right: 14px;
      padding-left: 16px;

      background-color: $blue-light;
      border-radius: 8px;

      .text {
        width: max-content;
        padding-top: 1px;
        padding-right: 14.68px;

        font-size: rem(15);
        font-weight: bold;
        line-height: rem(31);
        text-align: center;
        letter-spacing: 0;
      }

      .removeChipAction {
        width: auto;
        padding: 0 5px;
        line-height: rem(33);
        color: $grey-text;

        .Icon.Close {
          width: 12px;
          height: 12px;
        }
      }

      @media (max-width: $container-xs-width) {
        &:first-child {
          margin-left: 20px;
        }

        &:last-child {
          margin-right: 20px;
        }
      }
    }
  }

  @media (max-width: $container-lg1-min-width) {
    width: unset;
  }
}
