@import '@/_styles/import';
.header {
  height: 110px;
  background-color: $white;
  border-bottom: 1px solid $lightwhite;
  box-shadow: 0 2px 4px 0 #0000001a;
}

.toggle {
  position: absolute;
  top: 22px;
  right: 28px;

  width: 250px;
  margin: 0 auto;
}

.details {
  display: flex;

  .detailsTitle {
    position: absolute;
    margin-top: -30px;

    @include font($size: rem(13), $line-height: rem(13), $weight: 500, $color: $grey-text);
  }

  .regionsList {
    width: 48%;
  }

  .regionsListBox {
    overflow-y: auto;
    height: 380px;
    padding-top: 30px;
    padding-right: 15px;
  }

  .regionsListBox,
  .detailsTitle {
    padding-left: 30px;
  }

  .itemsList {
    flex: 1 1;
    width: 52%;
  }

  .itemsListBox {
    overflow-y: auto;
    height: 380px;
    padding: 30px 30px 0;
  }

  .itemsListBox,
  .itemsDetailsTitle {
    padding-left: 15px;
  }
}
